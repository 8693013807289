import { AnimatePresence, LazyMotion, m } from 'framer-motion'
import { CheckoutProvider } from 'lib-shopify'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import localFont from 'next/font/local'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode, useEffect } from 'react'

import '../styles/globals.css'

import CookieConsent from '@components/cookieConsent'
import SmoothScroll from '@components/layout/smoothScroll'
import TransitionLayout from '@components/layout/transition'
import GlobalMetaTags from '@components/metaTags'
import CartPanel from '@components/views/shop/cartPanel'

import { PageTransitionProvider } from '@lib/hooks/usePageTransition'
import { shopifyClient } from '@lib/shopify'
import { trueVh } from '@lib/utils'

const Nav = dynamic(() => import('@components/nav'), { ssr: true })
const PageLoader = dynamic(() => import('@components/pageLoader'), {
  ssr: false,
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface NextAppProps extends AppProps {
  Component: NextPageWithLayout
}

const font = localFont({ src: '../public/font/AspektaVF.woff2' })

export default function App({ Component, pageProps }: NextAppProps) {
  const router = useRouter()

  useEffect(() => {
    trueVh()
    window.addEventListener('resize', trueVh)
    return () => {
      window.removeEventListener('resize', trueVh)
    }
  }, [])

  return (
    <>
      <GlobalMetaTags />
      <LazyMotion features={loadFramerfeatures} strict>
        <PageTransitionProvider offTimeout={1500}>
          <SmoothScroll>
            <m.div className={`${font.className} font-aspekta`}>
              <CookieConsent />
              <CheckoutProvider client={shopifyClient}>
                <Nav />

                {/* page loader */}
                <PageLoader />

                <AnimatePresence
                  mode="wait"
                  initial={false}
                  onExitComplete={() => window.scrollTo(0, 0)}
                >
                  <TransitionLayout key={router.pathname}>
                    <Component {...pageProps} />
                  </TransitionLayout>
                </AnimatePresence>

                <CartPanel />
              </CheckoutProvider>
            </m.div>
          </SmoothScroll>
        </PageTransitionProvider>
      </LazyMotion>
    </>
  )
}

// Make sure to return the specific export containing the feature bundle.
const loadFramerfeatures = () =>
  import('../lib/framerMotionFeatures').then(res => res.default)
