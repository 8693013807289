import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import ShopifyBuy from 'shopify-buy'

export type Checkout = {
  addCustomerEmail?: (email: string) => Promise<void>
  addItem?: (variantId: string, quantity: number) => Promise<void>
  updateQuantity?: (lineItemId: string, quantity: number) => Promise<void>
  removeItem?: (lineItemId: string) => Promise<void>
  buyNow?: (variantId: string, quantity: number) => Promise<void>
  goToCheckout?: () => Promise<void>
}

interface CartState {
  value: ShopifyBuy.Checkout | undefined
  open: boolean
  toggle: () => void
  close: () => void
}

export type GlobalCheckoutState = {
  cart: CartState
  loading: boolean
  checkout: Checkout
  client?: ShopifyBuy
}

const initialState: GlobalCheckoutState = {
  cart: { value: undefined, open: false, toggle: () => {}, close: () => {} },
  loading: true,
  checkout: {},
  client: undefined,
}

type ProviderValue = {
  cart: CartState
  checkout: Checkout
  loading: boolean
  client: ShopifyBuy
}

export const store = createContext<GlobalCheckoutState>(initialState)
const { Provider } = store

const useCheckout: () => GlobalCheckoutState = () => useContext(store)

interface ICheckoutProvider {
  children: React.ReactNode | React.ReactNode[]
  client: GlobalCheckoutState['client']
}

export const CheckoutProvider = ({ children, client }: ICheckoutProvider) => {
  if (!client)
    throw new Error(
      'Shopify client not yet initialized. CheckoutProvider needs an initialized Shopify client'
    )

  const reducer = useCallback(checkoutReducer, [])

  const [state, dispatch] = useReducer(reducer, initialState)
  const [checkoutId, setCheckoutId] = useState<string>('')

  /**
   * get checkout id and initialize cart object
   */
  const initializeCart = useCallback(async () => {
    const id: string | null = localStorage.getItem('checkoutId')
    let newCart: ShopifyBuy.Checkout

    if (!id) {
      newCart = await client.checkout.create()
      localStorage.setItem('checkoutId', newCart.id || (newCart as any))
    } else {
      try {
        newCart = await client.checkout.fetch(id as string)
      } catch (e) {
        console.warn('Invalid checkoutId, creating a new one')
        newCart = await client.checkout.create()
        localStorage.setItem('checkoutId', newCart.id || (newCart as any))
      }
    }

    setCheckoutId(newCart.id)
    dispatch({ type: 'INITIALIZE_CART', payload: { cart: newCart } })
  }, [checkoutId])

  /**
   * add details to checkout such as email etc.
   */
  const addCustomerEmail = useCallback(
    async (email: string) => {
      dispatch({ type: 'LOADING' })

      try {
        const newCart = await client.checkout.updateEmail(checkoutId, email)

        dispatch({ type: 'ADD_ITEM', payload: { cart: newCart } })
      } catch (error) {
        console.error(error)
      }
    },
    [checkoutId]
  )

  /**
   * add item to cart
   * @param variantId
   * @param quantity
   */
  const addItem = useCallback(
    async (variantId: string, quantity: number) => {
      dispatch({ type: 'LOADING' })

      const lineItemsToAdd = [{ variantId: variantId, quantity: quantity }]

      try {
        const newCart = await client.checkout.addLineItems(
          checkoutId,
          lineItemsToAdd
        )

        dispatch({ type: 'ADD_ITEM', payload: { cart: newCart } })
      } catch (error) {
        console.error(error)
      }
    },
    [checkoutId]
  )

  /**
   * update item quantity
   */
  const updateQuantity = useCallback(
    async (lineItemId: string, quantity: number) => {
      const lineItemsToUpdate = [{ id: lineItemId, quantity }]
      const newCart = await client.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate
      )
      // setCart(newCart)
      dispatch({ type: 'UPDATE_QUANTITY', payload: { cart: newCart } })
    },
    [checkoutId]
  )

  /**
   * remove item from cart
   * @param lineid
   */
  const removeItem = useCallback(
    async (lineid: string) => {
      const lineItemIdsToRemove = [lineid]
      const newCart: ShopifyBuy.Checkout =
        await client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove)
      // setCart(newCart)
      dispatch({ type: 'REMOVE_ITEM', payload: { cart: newCart } })

      if (newCart.lineItems.length === 0) {
        dispatch({ type: 'CLOSE_CART' })
      }
    },
    [checkoutId]
  )

  /**
   * redirect to checkout page with selected variant
   * @param variantId
   * @param quantity
   */
  const buyNow = useCallback(
    async (variantId: string, quantity: number = 1) => {
      dispatch({ type: 'LOADING' })

      let cart: ShopifyBuy.Checkout

      if (variantId && quantity) {
        const lineItemsToAdd = [{ variantId: variantId, quantity: quantity }]
        cart = await client.checkout.addLineItems(checkoutId, lineItemsToAdd)
        dispatch({ type: 'ADD_ITEM', payload: { cart } })
        // localStorage.removeItem('checkoutId')
        location.href = cart.webUrl
      } else if (cartState.value) {
        // console.log(cartState.value)
        // localStorage.removeItem('checkoutId')
        location.href = cartState.value.webUrl
      }
    },
    [checkoutId]
  )

  const goToCheckout = useCallback(async () => {
    dispatch({ type: 'LOADING' })

    // console.log(cartState.value)
    // localStorage.removeItem('checkoutId')
    if (!cartState.value) return
    location.href = cartState.value.webUrl
  }, [checkoutId])

  const toggleOpen = () => dispatch({ type: 'TOGGLE_OPEN' })

  const closeCart = () => dispatch({ type: 'CLOSE_CART' })

  useEffect(() => {
    initializeCart()
  }, [])

  const cartState: CartState = {
    value: state.cart.value,
    open: state.cart.open,
    toggle: toggleOpen,
    close: closeCart,
  }

  const checkout: Checkout = {
    addCustomerEmail,
    addItem,
    updateQuantity,
    removeItem,
    buyNow,
    goToCheckout,
  }

  const injectedValue: ProviderValue = {
    cart: cartState,
    checkout: checkout,
    loading: state.loading,
    client,
  }

  return <Provider value={injectedValue}>{children}</Provider>
  // return [cartState, checkout]
}

const checkoutReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'INITIALIZE_CART':
      return {
        ...state,
        cart: {
          ...state.cart,
          value: payload.cart,
        },
        loading: false,
      }
    case 'ADD_ITEM':
      return {
        ...state,
        cart: {
          ...state.cart,
          value: payload.cart,
        },
        loading: false,
      }
    case 'UPDATE_QUANTITY':
      return {
        ...state,
        cart: {
          ...state.cart,
          value: payload.cart,
        },
        loading: false,
      }
    case 'REMOVE_ITEM':
      return {
        ...state,
        cart: {
          ...state.cart,
          value: payload.cart,
        },
        loading: false,
      }
    case 'LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'CLOSE_CART':
      return {
        ...state,
        cart: {
          ...state.cart,
          open: false,
        },
      }
    case 'TOGGLE_OPEN':
      return {
        ...state,
        cart: {
          ...state.cart,
          open: !state.cart.open,
        },
      }
    default:
      throw new Error()
  }
}

export default useCheckout
