import React, { ReactElement, createContext, useContext, useState } from 'react'
import {
  ProductOption,
  ProductPriceRange,
  ProductVariant,
  SelectedOption,
} from 'shopify-buy'

import { useCheckout } from '..'

async function variantForOptions(product, options) {
  return product.variants.find(variant => {
    return variant.selectedOptions.every(selectedOption => {
      return options[selectedOption.name] === selectedOption.value.valueOf()
    })
  })
}

/**
 *
 */
const productOptionsContext =
  createContext<ProductOptionsContextType>(undefined)

/**
 *
 */
const useProductOptions = () => useContext(productOptionsContext)

/**
 *
 */
const ProductOptionsProvider = ({
  initOptions,
  children,
}: {
  initOptions: IProductOptionsProvider
  children: ReactElement | ReactElement[]
}) => {
  const {
    id,
    handle,
    availableForSale,
    options,
    firstAvailableVariant,
    priceRange,
  } = initOptions

  const { client } = useCheckout()

  const [selected, setSelected] = useState(
    availableForSale ? firstAvailableVariant?.selectedOptions : null
  )

  function selectOption(optName: string, value: string): void {
    if (!client) throw new Error(CLIENT_NOT_AVAILABLE_MESSAGE)

    const optIndex = options.findIndex(opt => opt.name === optName)

    setSelected((prev: SelectedOption[]) => {
      const newState = [...prev]
      newState[optIndex] = { name: optName, value: value }

      return newState
    })
  }

  /**
   *
   * @param handle The handle of the product
   * @param options An object with key-value pairs for each option (optionName: optionValue)
   * @returns An object with the id and the availability of the selected product
   */
  async function checkAvailability(): Promise<ProductVariant> {
    if (!client) throw new Error(CLIENT_NOT_AVAILABLE_MESSAGE)

    const p = await client.product.fetchByHandle(handle)
    const v = await variantForOptions(p, selected)

    return v
  }

  const injectedValue: ProductOptionsContext = {
    id,
    handle,
    availableForSale,
    priceRange,
    options,
    selected,
    selectOption,
    checkAvailability,
  }

  return (
    <productOptionsContext.Provider value={injectedValue}>
      {children}
    </productOptionsContext.Provider>
  )
}

type ProductOptionsContextType = ProductOptionsContext | undefined

interface ProductOptionsContext {
  id: string
  handle: string
  availableForSale: boolean
  priceRange: ProductPriceRange
  options: ProductOption[]
  selected: SelectedOption[]
  selectOption: (optName: string, value: string) => void
  checkAvailability: () => Promise<ProductVariant>
}

interface IProductOptionsProvider {
  id: string
  handle: string
  availableForSale: boolean
  options: ProductOption[]
  priceRange: ProductPriceRange
  [key: string]: any
}

const CLIENT_NOT_AVAILABLE_MESSAGE =
  'Client not available. Have you added the CheckoutProvider around the ProductOptionsProvider?'

export { ProductOptionsProvider }
export default useProductOptions
